import {authUniqueStateId} from './auth.model';
import {
  LoginPasswordData,
  PasswordConfirm,
  PasswordUpdateData,
  PatientActivationInfo,
  Preferences,
  Quest,
  RegisterData,
  TOTPDevice,
  TwoFactorValidate,
  User,
} from '../../symbols';

export class Init {
  static readonly type = `${authUniqueStateId} ${Init.name}`;
}

// Actions
export class CheckSession {
  static readonly type = `${authUniqueStateId} ${CheckSession.name}`;

  constructor(public redirect = true) {}
}

export class SessionSuccess {
  static readonly type = `${authUniqueStateId} ${SessionSuccess.name}`;

  constructor(public user: User) {}
}

export class SessionClosed {
  static readonly type = `${authUniqueStateId} ${SessionClosed.name}`;

  constructor(public user: User, public redirect = true) {}
}

export class LoginWithGoogle {
  static readonly type = `${authUniqueStateId} ${LoginWithGoogle.name}`;

  constructor(public token: string, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class RegisterWithGoogle {
  static readonly type = `${authUniqueStateId} ${RegisterWithGoogle.name}`;

  constructor(
    public token: string,
    public redirect: string = null,
    public queryParams?: {[key: string]: any},
    public type_of_registration?: number
  ) {}
}

export class LoginWithFacebook {
  static readonly type = `${authUniqueStateId} ${LoginWithFacebook.name}`;

  constructor(public token: string, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class RegisterWithFacebook {
  static readonly type = `${authUniqueStateId} ${RegisterWithFacebook.name}`;

  constructor(
    public token: string,
    public redirect: string = null,
    public queryParams?: {[key: string]: any},
    public type_of_registration?: number
  ) {}
}

export class LoginWithEmailAndPassword {
  static readonly type = `${authUniqueStateId} ${LoginWithEmailAndPassword.name}`;

  constructor(public data: LoginPasswordData, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class LoginSuccess {
  static readonly type = `${authUniqueStateId} ${LoginSuccess.name}`;

  constructor(public user: User, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class LoginFail {
  static readonly type = `${authUniqueStateId} ${LoginFail.name}`;

  constructor(public err: any) {}
}

export class Logout {
  static readonly type = `${authUniqueStateId} ${Logout.name}`;
}

export class LogoutSuccess {
  static readonly type = `${authUniqueStateId} ${LogoutSuccess.name}`;
}

export class RegistrationUser {
  static readonly type = `${authUniqueStateId} ${RegistrationUser.name}`;

  constructor(
    public data: RegisterData,
    public redirect?: string,
    public sharingKey?: string,
    public queryParams?: {
      [key: string]: any;
    }
  ) {}
}

export class ResetPassword {
  static readonly type = `${authUniqueStateId} ${ResetPassword.name}`;

  constructor(public email: string) {}
}

export class ResetPasswordSuccess {
  static readonly type = `${authUniqueStateId} ${ResetPasswordSuccess.name}`;

  constructor(public detail: string) {}
}

export class ResetPasswordFail {
  static readonly type = `${authUniqueStateId} ${ResetPasswordFail.name}`;

  constructor(public err: any) {}
}

export class ResetPasswordConfirm {
  static readonly type = `${authUniqueStateId} ${ResetPasswordConfirm.name}`;

  constructor(public passwordUpdateData: PasswordUpdateData) {}
}

export class ResetPasswordConfirmSuccess {
  static readonly type = `${authUniqueStateId} ${ResetPasswordConfirmSuccess.name}`;

  constructor(public detail: string) {}
}

export class ResetPasswordConfirmFail {
  static readonly type = `${authUniqueStateId} ${ResetPasswordConfirmFail.name}`;

  constructor(public err: any) {}
}

export class ActivatePatientAccount {
  static readonly type = `${authUniqueStateId} ${ActivatePatientAccount.name}`;

  constructor(public passwordUpdateData: PasswordUpdateData) {}
}

export class ActivatePatientAccountSuccess {
  static readonly type = `${authUniqueStateId} ${ActivatePatientAccountSuccess.name}`;

  constructor(public detail: string) {}
}

export class ActivatePatientAccountFail {
  static readonly type = `${authUniqueStateId} ${ActivatePatientAccountFail.name}`;

  constructor(public err: any) {}
}

export class ChangePassword {
  static readonly type = `${authUniqueStateId} ${ChangePassword.name}`;

  constructor(public passwords: PasswordConfirm) {}
}

export class ChangePasswordSuccess {
  static readonly type = `${authUniqueStateId} ${ChangePasswordSuccess.name}`;

  constructor(public detail: string) {}
}

export class ChangePasswordFail {
  static readonly type = `${authUniqueStateId} ${ChangePasswordFail.name}`;

  constructor(public detail: string) {}
}

export class ConfirmEmail {
  static readonly type = `${authUniqueStateId} ${ConfirmEmail.name}`;

  constructor(public key: string) {}
}

export class ConfirmEmailSuccess {
  static readonly type = `${authUniqueStateId} ${ConfirmEmailSuccess.name}`;

  constructor(public data: string) {}
}

export class ConfirmEmailFail {
  static readonly type = `${authUniqueStateId} ${ConfirmEmailFail.name}`;

  constructor(public err: any) {}
}

export class SendRegisterForm {
  static readonly type = `${authUniqueStateId} ${SendRegisterForm.name}`;

  constructor(public formValue: Quest) {}
}

export class EnablePreview {
  static readonly type = `${authUniqueStateId} ${EnablePreview.name}`;
}

export class DisablePreview {
  static readonly type = `${authUniqueStateId} ${DisablePreview.name}`;
}

// Events
export class LoginRedirect {
  static readonly type = `${authUniqueStateId} ${LoginRedirect.name}`;
}

export class SendFormSuccess {
  static readonly type = `${authUniqueStateId} ${SendFormSuccess.name}`;
  public redirect = null;

  constructor(public message: any) {}
}

export class RegistrationSuccess {
  static readonly type = `${authUniqueStateId} ${RegistrationSuccess.name}`;

  constructor(public user: User, public redirect?: string, public queryParams?: {[key: string]: any}) {}
}

export class RegistrationFail {
  static readonly type = `${authUniqueStateId} ${RegistrationFail.name}`;

  constructor(public err: any) {}
}

export class Loading {
  static readonly type = `${authUniqueStateId} ${Loading.name}`;
}

export class Loaded {
  static readonly type = `${authUniqueStateId} ${Loaded.name}`;
}

export class UpdateUser {
  static readonly type = `${authUniqueStateId} ${UpdateUser.name}`;

  constructor(public user: Partial<User>) {}
}

export class UpdateUserSuccess {
  static readonly type = `${authUniqueStateId} ${UpdateUserSuccess.name}`;

  constructor(public user: User) {}
}

export class ReloadCurrentUser {
  static readonly type = `${authUniqueStateId} ${ReloadCurrentUser.name}`;
}

export class ReloadCurrentUserSuccess {
  static readonly type = `${authUniqueStateId} ${ReloadCurrentUserSuccess.name}`;

  constructor(public user: User) {}
}

export class SendEmailConfirmation {
  static readonly type = `${authUniqueStateId} ${SendEmailConfirmation.name}`;

  constructor(public showMessage: boolean = true) {}
}

export class SendEmailConfirmationSuccess {
  static readonly type = `${authUniqueStateId} ${SendEmailConfirmationSuccess.name}`;

  constructor(public message: string, public showMessage: boolean = true) {}
}

export class SendEmailConfirmationFail {
  static readonly type = `${authUniqueStateId} ${SendEmailConfirmationFail.name}`;

  constructor(public err: any) {}
}

export class GetPreferences {
  static readonly type = `${authUniqueStateId} ${GetPreferences.name}`;
}

export class GetPreferencesSuccess {
  static readonly type = `${authUniqueStateId} ${GetPreferencesSuccess.name}`;

  constructor(public preferences: Preferences) {}
}

export class SavePreferences {
  static readonly type = `${authUniqueStateId} ${SavePreferences.name}`;

  constructor(public preferences: Preferences) {}
}

export class GetTOTPDevices {
  static readonly type = `${authUniqueStateId} ${GetTOTPDevices.name}`;
}

export class GetTOTPDevicesSuccess {
  static readonly type = `${authUniqueStateId} ${GetTOTPDevicesSuccess.name}`;

  constructor(public devices: TOTPDevice[]) {}
}

export class CreateTOTPDevice {
  static readonly type = `${authUniqueStateId} ${CreateTOTPDevice.name}`;
}

export class RemoveTOTPDevice {
  static readonly type = `${authUniqueStateId} ${RemoveTOTPDevice.name}`;

  constructor(public device: TOTPDevice) {}
}

export class ConfirmTOTPDevice {
  static readonly type = `${authUniqueStateId} ${ConfirmTOTPDevice.name}`;

  constructor(public device: TOTPDevice) {}
}

export class TwoFactorTokenValidate {
  static readonly type = `${authUniqueStateId} ${TwoFactorTokenValidate.name}`;

  constructor(public data: TwoFactorValidate, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class TwoFactorTokenValidateSuccess {
  static readonly type = `${authUniqueStateId} ${TwoFactorTokenValidateSuccess.name}`;

  constructor(public user: User, public redirect: string = null, public queryParams?: {[key: string]: any}) {}
}

export class TwoFactorTokenValidateFail {
  static readonly type = `${authUniqueStateId} ${TwoFactorTokenValidateFail.name}`;

  constructor(public err: any) {}
}

export class FinishTutorial {
  static readonly type = `${authUniqueStateId} ${FinishTutorial.name}`;
}

export class FinishTutorialSuccess {
  static readonly type = `${authUniqueStateId} ${FinishTutorialSuccess.name}`;
}

export class SetMailchimpTag {
  static readonly type = `${authUniqueStateId} ${SetMailchimpTag.name}`;

  constructor(public tag: string) {}
}

export class SetMailchimpTagSuccess {
  static readonly type = `${authUniqueStateId} ${SetMailchimpTagSuccess.name}`;

  constructor(public user: User) {}
}

export class ActivatePatient {
  static readonly type = `${authUniqueStateId} ${ActivatePatient.name}`;

  constructor(public dateOfBirth: string, public key: string) {}
}

export class ActivatePatientSuccess {
  static readonly type = `${authUniqueStateId} ${ActivatePatientSuccess.name}`;

  constructor(public activationInfo: PatientActivationInfo) {}
}

export class ActivatePatientFail {
  static readonly type = `${authUniqueStateId} ${ActivatePatientFail.name}`;

  constructor(public err: any) {}
}
