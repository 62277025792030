import {Injectable} from '@angular/core';


@Injectable()
export class Settings {

  BASE_URL = 'http://localhost:4200';
  LOGIN_URL = '/auth/sign-in/';
  LOGIN_REDIRECT_URL = '/dashboard/';
  LOGOUT_REDIRECT_URL = '/';
  AUTHENTICATION_REQUIRED_REDIRECT_URL = '/auth/sign-up/';

  ACCOUNT_EMAIL_VERIFICATION = 'mandatory';
  ACCOUNT_CONFIRM_EMAIL_ON_GET = true;
  ACCOUNT_EMAIL_CONFIRMATION_ANONYMOUS_REDIRECT_URL = null;
  ACCOUNT_EMAIL_CONFIRMATION_AUTHENTICATED_REDIRECT_URL = null;
  ACCOUNT_LOGOUT_REDIRECT_URL = '/';

  DELAY_TIME = 600;
  TIME_ZONE = 'America/Los_Angeles';

  GOOGLE_PLACES_API_KEY = 'AIzaSyBqjHfpIFhmoJ8U8HwHoiV1R2TnZquMPtY';
  SUBMISSION_UPDATE_INTERVAL = 0; // 0 to stop periodical updates

  SUBSCRIPTION_PRICE = 99.00;

  PRODUCT_PREMIUM_ACCOUNT = 'upgrade-account';
  PRODUCT_RESERVATION = 'reservation';
  PRODUCT_MRI_RECORDS_REQUEST = 'mri-records-request';
  PRODUCT_3D_PRINT_FULL_SIZE = '3d-print-full-size';
  PRODUCT_3D_PRINT_HALF_SIZE = '3d-print-half-size';
  PRODUCT_BRAINKEY_REPORT = 'brainkey-report';

  PRICE_REPORTS_ACCESS = 299; // $
  PRICE_RESERVE_BRAINKEY_SCAN = 390; // $
  PRICE_REQUEST_RECORDS = 125; // $
  PRICE_PRINT_3D_BRAIN_FULL_SIZE = 1000; // $
  PRICE_PRINT_3D_BRAIN_HALF_SIZE = 200; // $

  TITLE_DEFAULT = 'BrainKey';
  TITLE_SEPARATOR = ' | ';
  META_DEFAULT = [{
    name: 'description',
    content: `BrainKey is a personal brain dashboard built by neuroscientists with the goal of empowering people who have had a brain MRI.`,
  }];
}
