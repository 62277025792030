import {DjangoAuthToken, Preferences, TOTPDevice, User} from '../../symbols';


/** Unique identifier for the dashboard chart data state. */
export const authUniqueStateId = '_Auth_';

export interface AuthStateModel {
  initialized: boolean;
  loading: boolean;
  loaded: boolean;
  token?: DjangoAuthToken;
  user?: User;
  previewMode: boolean;
  preferences: Preferences;
  hasAccount: boolean;
  sendEmailConfirmationLoading: boolean;
  sendEmailConfirmationLoaded: boolean;
  devices: TOTPDevice[];
  unconfirmedDevice: TOTPDevice;
}


